import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function Edit(props) {
  const [open, setOpen] = useState(false);
  const [object, setObject] = useState({
    titulo: '', valor: '', quantidade: '',  
  });
    
  // Open the modal form and update the object state
  const handleClickOpen = () => {
    setObject({
      titulo: props.data.row.titulo,
      valor: props.data.row.valor,
      quantidade: props.data.row.quantidade,
    })      
    setOpen(true);
  }
  
  // Close the modal form 
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setObject({...object, 
      [event.target.name]: event.target.value});
  }

  // Update object and close modal form 
  const handleSave = () => {
    props.update(object, props.data.id);
    handleClose();
  }
 
  return(
    <div>
      <IconButton onClick={handleClickOpen}>
          <EditIcon quantidade="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
              <TextField label="titulo" name="titulo" autoFocus
                variant="standard" value={object.titulo} 
                onChange={handleChange}/>
              <TextField label="valor" name="valor" 
                variant="standard" value={object.valor} 
                onChange={handleChange}/>
              <TextField label="quantidade" name="quantidade" 
                variant="standard" value={object.quantidade} 
                onChange={handleChange}/>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>            
    </div>
  );
}

export default Edit;