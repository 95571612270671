import { Link } from "react-router-dom";
// import './Home.scss';

const Home = () => {
    return (
        <section id="admin" className="service-section">
        <div className="container">
          <div className="page-breadcrumb-content text-center ">
            <h1>Home</h1>
            <ul className="page-breadcrumb-links">
                <li>...</li>
            </ul>
          </div>
          <div className="icon-examples hide">
            <div className="icon">
              <i className="flaticon-042-monitor"></i>
              <i className="flaticon-001-worker"></i>
              <i className="flaticon-002-welding"></i>
              <i className="flaticon-003-waste"></i>
              <i className="flaticon-004-walkie"></i>
              <i className="flaticon-005-valve"></i>
              <i className="flaticon-006-truck"></i>
              <i className="flaticon-007-tools"></i>
              <i className="flaticon-008-machine"></i>
              <i className="flaticon-009-storage"></i>
              <i className="flaticon-010-tank"></i>
              <i className="flaticon-011-siren"></i>
              <i className="flaticon-012-scheme"></i>
              <i className="flaticon-013-danger"></i>
              <i className="flaticon-014-robot"></i>
              <i className="flaticon-015-cart"></i>
              <i className="flaticon-016-gear"></i>
              <i className="flaticon-017-pump"></i>
              <i className="flaticon-018-power"></i>
              <i className="flaticon-019-power"></i>
              <i className="flaticon-020-planning"></i>
              <i className="flaticon-021-worker"></i>
              <i className="flaticon-022-tank"></i>
              <i className="flaticon-023-microprocessor"></i>
              <i className="flaticon-024-statistics"></i>
              <i className="flaticon-025-meter"></i>
              <i className="flaticon-026-mechanism"></i>
              <i className="flaticon-027-material"></i>
              <i className="flaticon-028-manufacturing"></i>
              <i className="flaticon-029-manufacturing"></i>
              <i className="flaticon-030-management"></i>
              <i className="flaticon-031-machine"></i>
              <i className="flaticon-032-gears"></i>
              <i className="flaticon-033-laser"></i>
              <i className="flaticon-034-industrial"></i>
              <i className="flaticon-035-parcel"></i>
              <i className="flaticon-036-gears"></i>
              <i className="flaticon-037-forklift"></i>
              <i className="flaticon-038-food"></i>
              <i className="flaticon-039-factory"></i>
              <i className="flaticon-040-factory"></i>
              <i className="flaticon-041-eco"></i>
              <i className="flaticon-042-monitor"></i>
              <i className="flaticon-043-wheel"></i>
              <i className="flaticon-044-conveyor"></i>
              <i className="flaticon-045-controller"></i>
              <i className="flaticon-046-control"></i>
              <i className="flaticon-047-control"></i>
              <i className="flaticon-048-chemical"></i>
              <i className="flaticon-049-container"></i>
              <i className="flaticon-050-boxes"></i>
                  </div>
          </div>
          <div className="service-item-wrapper space__bottom--m40">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12 space__bottom--40">
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <Link to="/produto">
                                    <img width="370" height="247" src="assets/img/service/service-3-m.jpg" className="img-fluid" alt="" />
                                </Link>
                            </div>
                            <div className="icon">
                                <i className="flaticon-021-worker"></i>
                            </div>
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                                <Link to="/produto">Produtos</Link>
                            </h3>
                            <p className="subtitle">
                                Gerencie os produtos
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 space__bottom--40">
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <Link to="/services">
                                    <img width="370" height="247" src="assets/img/service/service-1-m.jpg" className="img-fluid" alt="" />
                                </Link>
                            </div>
                            <div className="icon">
                                <i className="flaticon-036-gears"></i>
                            </div>
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                                <Link to="/services">Grupo de Estudos</Link>
                            </h3>
                            <p className="subtitle">
                                Gerencie o grupo de estudos Musicais
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 space__bottom--40">
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <Link to="/invoices">
                                    <img width="370" height="246" src="assets/img/service/service-2-m.jpg" className="img-fluid" alt="" />
                                </Link>
                            </div>
                            <div className="icon">
                                <i className="flaticon-030-management"></i>
                            </div>
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                                <Link to="/invoices">Membros</Link>
                            </h3>
                            <p className="subtitle">
                                Gerencie o cadastro de membros
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
          </div>
      </div>
    </section>

    );
  };
  
  export default Home;