import axios from 'axios';


// // Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response 1<-");
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("response 2 <-");
    //TODO: tratar erro quando for de token expirado
    // if(error.response.text == "token expirado..."){

    // }

    //ERRO DE USUARIO E SENHA
    if(error.response && error.response.data.status !== "error"){
        window.history.replaceState({}, '', '/login');
        window.location.reload();
    }

    //TOKEN
    if(error.response && error.response.data.message === "Faça login novamente. sem token: "){
        alert("Tempo expirado, faça o login novamente.");
        window.history.replaceState({}, '', '/login');
        window.location.reload();
    }

    //EERRO DE ENTIDADE ERRADA
    if(error.message && error.message === "Network Error"){
        console.info("Erro ao buscar dados.");
    }

    return Promise.reject(error);
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("jwt"); 
    config.headers.Authorization =  "Bearer " + token;
    return config;
}, function (error) {
    return Promise.reject(error);
});