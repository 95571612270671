import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function Add(props) {
  const [open, setOpen] = useState(false);
  const [object, setObject] = useState({
    titulo: '',
    valor: '',
    quantidade: '', 
  });

  // Open the modal form
  const handleClickOpen = () => {
    setOpen(true);
  };
    
  // Close the modal form 
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setObject({...object, [event.target.name]: event.target.value});
  }

  // Save object and close modal form 
  const handleSave = () => {
    props.addObject(object);
    setObject({
      titulo: '',
      valor: '',
      quantidade: '', 
    });
    handleClose();
  }
  
  return(
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        New
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
              <TextField label="titulo" name="titulo" autoFocus
                variant="standard" value={object.titulo} 
                onChange={handleChange}/>
              <TextField label="valor" name="valor" 
                variant="standard" value={object.valor} 
                onChange={handleChange}/>
              <TextField label="quantidade" name="quantidade" 
                variant="standard" value={object.quantidade} 
                onChange={handleChange}/>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>            
    </div>
  );
}

export default Add;
